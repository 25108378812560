<template>
	<div
		class="pagination margin-top-xl"
		v-if="showPagination && numberOfPages > 1"
	>
		<TnButton
			size="s"
			@click="prevPage"
			:disabled="page < 1"
			class="margin-right-auto"
			tertiary
		>
			<TnIcon name="chevron-left" />
		</TnButton>
		<ul>
			<li>
				<a
					href="javascript:"
					:class="{ active: currentPage === 1 }"
					@click="setPage(0)"
					>1</a
				>
			</li>
			<li v-if="showDotsLeft">
				<a
					href="javascript:"
					class="dots"
					>...</a
				>
			</li>
			<li
				v-for="(page, i) in pages"
				:key="i"
			>
				<a
					href="javascript:"
					:class="{ active: currentPage === page }"
					@click="setPage(page - 1)"
					>{{ page }}</a
				>
			</li>
			<li v-if="showDotsRight">
				<a
					href="javascript:"
					class="dots"
					>...</a
				>
			</li>
			<li>
				<a
					href="javascript:"
					:class="{ active: currentPage === numberOfPages }"
					@click="setPage(numberOfPages - 1)"
					>{{ numberOfPages }}</a
				>
			</li>
		</ul>
		<TnButton
			size="s"
			style="margin-left: auto"
			@click="nextPage"
			:disabled="currentPage >= numberOfPages"
			tertiary
		>
			<TnIcon name="chevron-right" />
		</TnButton>
	</div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, defineProps, defineEmits } from "vue";

// Props
const props = defineProps<{
	numberOfPages: number;
	showPagination: boolean;
	page: number;
	currentPage: number;
}>();

// Emits
const emit = defineEmits(["update:page"]);

// Reactive state
const width = ref(null);

// Computed properties
const maxNumberOfPages = computed(() => {
	if (props.numberOfPages <= 6) {
		return props.numberOfPages;
	} else if (width.value >= 1920) {
		return 41;
	} else if (width.value >= 1000) {
		return 21;
	} else if (width.value >= 650) {
		return 15;
	} else if (width.value >= 500) {
		return 11;
	} else if (width.value < 430) {
		return 5;
	}
	return 7;
});

const prevSibling = computed(() => props.currentPage - 1);
const nextSibling = computed(() => props.currentPage + 1);

const showDotsLeft = computed(() => {
	return (
		maxNumberOfPages.value &&
		props.numberOfPages > maxNumberOfPages.value &&
		(prevSibling.value !== 0 || prevSibling.value >= 1) &&
		!shouldStartAtOne.value &&
		width.value >= 320
	);
});

const showDotsRight = computed(() => {
	return maxNumberOfPages.value &&
		props.currentPage !== props.numberOfPages &&
		props.numberOfPages > maxNumberOfPages.value &&
		nextSibling.value !== props.numberOfPages &&
		props.currentPage + maxNumberOfPages.value / 2 <= props.numberOfPages
		? true
		: false;
});

const shouldStartAtOne = computed(() => {
	return props.currentPage - maxNumberOfPages.value / 2 <= 1;
});

const startIndex = computed(() => {
	if (
		props.currentPage === 1 ||
		props.currentPage === 2 ||
		shouldStartAtOne.value ||
		maxNumberOfPages.value >= props.numberOfPages
	) {
		return 1;
	} else if (
		props.currentPage + maxNumberOfPages.value / 2 <= props.numberOfPages &&
		!(props.currentPage - maxNumberOfPages.value / 2 < 1)
	) {
		return props.currentPage - Math.floor(maxNumberOfPages.value / 2);
	} else if (props.currentPage === props.numberOfPages) {
		return props.numberOfPages - maxNumberOfPages.value + 1;
	} else if (props.currentPage + maxNumberOfPages.value > props.numberOfPages + 2) {
		return props.numberOfPages - maxNumberOfPages.value + 1;
	} else if (props.currentPage - maxNumberOfPages.value / 2 <= 1) {
		return props.currentPage - maxNumberOfPages.value / 2 + 2;
	}
	return props.currentPage - 2;
});

const endIndex = computed(() => {
	return startIndex.value + maxNumberOfPages.value - 2;
});

const pages = computed(() => {
	const pagesArray = Array.from({ length: props.numberOfPages }, (_, i) => i + 1);
	return pagesArray.slice(
		startIndex.value,
		endIndex.value < props.numberOfPages ? endIndex.value : props.numberOfPages - 1,
	);
});

// Methods
const setPage = (newPage: number) => {
	emit("update:page", newPage);
};

const prevPage = () => {
	emit("update:page", props.page - 1);
};

const nextPage = () => {
	emit("update:page", props.page + 1);
};

const handleResize = () => {
	width.value = window.innerWidth;
};

// Lifecycle hooks
onMounted(() => {
	width.value = window.innerWidth;
	window.addEventListener("resize", handleResize);
});

onBeforeUnmount(() => {
	window.removeEventListener("resize", handleResize);
});
</script>
<style lang="scss" scoped>
:deep(.button) {
	align-items: flex-start !important;
}

.dots:hover {
	cursor: default;
}

.pagination {
	display: flex;
	overflow: hidden;
	scroll-padding-bottom: 100px;
	scroll-margin-bottom: 100px;

	ul {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 250px;
		width: 100%;
		align-self: center;
		white-space: nowrap;
		overflow: hidden;
		padding: 0 $spacing-s;

		li {
			display: inline-block;

			a {
				display: inline-block;
				text-align: center;
				width: $spacing-xl;
				height: $spacing-xl;
				line-height: $spacing-xl;
				text-decoration: none;
				color: inherit;
				border-radius: 7px;
				transition: background-color 0.2s ease;

				&:not(.dots):hover {
					background: rgba($color-cta-hover-background, 0.08);
				}

				&.active {
					background: $color-cta-active;
					color: $color-neutrals-white;
				}
			}
		}
	}

	@include breakpoint(mobile) {
		scroll-padding-bottom: 50px;
		scroll-margin-bottom: 50px;
	}
}
</style>
